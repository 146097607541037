@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Base guideline colors */
    --color-beterraba: 176 31 68;
    --color-tamarindo: 92 10 10;
    --color-acerola: 233 65 12;
    --color-farofa: 254 231 229;
    --color-farofa-tint: 254 246 246;
    --color-mostarda: 250 177 37;
    --color-laranja: 255 122 0;
    --color-acelga: 9 119 59;
    --color-acelga-shade: 15 59 19;
    --color-acelga-tint: 70 226 85;
    --color-bandeja: 3 33 110;

    /* Semantic colors for light mode */
    --color-background: var(--color-farofa);
    --color-background-contrast: var(--color-tamarindo);

    --color-header: var(--color-acerola);

    --color-accent: var(--color-beterraba);
    --color-accent-contrast: var(--color-farofa);
    --color-accent-hover: 123 22 48;

    --color-overlay: var(--color-farofa-tint);
    --color-overlay-contrast: var(--color-tamarindo);
    --color-overlay-positive: var(--color-acelga);
    --color-overlay-negative: var(--color-beterraba);
    --color-overlay-hover: 244 206 215;

    --color-highlight-a: var(--color-acerola);
    --color-highlight-a-contrast: var(--color-farofa-tint);
    --color-highlight-a-accent: var(--color-tamarindo);
    --color-highlight-a-hover: 163 46 8;

    --color-highlight-b: var(--color-acelga-shade);
    --color-highlight-b-contrast: var(--color-farofa-tint);
    --color-highlight-b-accent: var(--color-acelga-tint);
    --color-highlight-b-hover: 11 41 13;

    /* Semantic colors subset for Ionic components compatibility */
    --color-accent-rgb: 176, 31, 68;
    --color-overlay-rgb: 254, 246, 246;

    color-scheme: light;
  }

  body.dark {
    /* Dark mode only colors */
    --color-meianoite: 19 19 19;
    --color-escuro: 45 32 32;
    --color-quibebe: 255 186 204;

    /* Semantic colors for dark mode overrides */
    --color-background: var(--color-meianoite);
    --color-background-contrast: var(--color-farofa);

    --color-header: var(--color-laranja);

    --color-accent: var(--color-quibebe);
    --color-accent-contrast: var(--color-tamarindo);
    --color-accent-hover: 230 139 163;

    --color-overlay: var(--color-escuro);
    --color-overlay-contrast: var(--color-farofa);
    --color-overlay-positive: var(--color-acelga-tint);
    --color-overlay-negative: var(--color-laranja);
    --color-overlay-hover: 68 53 53;

    /* Semantic colors subset for Ionic components compatibility */
    --color-accent-rgb: 255, 186, 204;
    --color-overlay-rgb: 45, 32, 32;

    color-scheme: dark;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes slide-from-right {
    from {
      transform: translateX(30px);
    }
  }

  @keyframes slide-to-left {
    to {
      transform: translateX(-30px);
    }
  }

  ::view-transition-old(root) {
    animation:
      90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
  }

  ::view-transition-new(root) {
    animation:
      210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
  }

  @media (prefers-reduced-motion: reduce) {
    ::view-transition-old(root) {
      @apply animate-none;
    }

    ::view-transition-new(root) {
      @apply animate-none;
    }
  }

  select {
    @apply bg-[url('/assets/elements/icon-chevron-down-light.svg')];
  }

  body.dark select {
    @apply bg-[url('/assets/elements/icon-chevron-down-dark.svg')];
  }

  select option {
    @apply bg-overlay text-overlay-contrast;
  }

  select option:hover {
    @apply bg-accent text-accent-contrast;
  }

  [type='checkbox']:checked {
    @apply bg-[url('/assets/elements/icon-checkbox-light.svg')];
  }

  body.dark [type='checkbox']:checked {
    @apply bg-[url('/assets/elements/icon-checkbox-dark.svg')];
  }
}

@layer components {
  .body-link {
    @apply box-decoration-clone text-accent underline underline-offset-4 transition-colors hover:text-accent-hover focus:rounded-md focus:outline-none focus:ring focus:ring-accent-hover;
  }

  .button-base {
    @apply flex touch-manipulation flex-row items-center gap-2 rounded-full p-2 font-geometric font-semibold text-accent transition-all focus:outline-none focus:ring focus:ring-accent focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-60;
  }

  .button-outline {
    @apply button-base border-2 border-accent active:bg-accent/30 sm:px-4 sm:py-2;
  }

  button.button-outline:enabled,
  a.button-outline {
    @apply hover:bg-accent/10 hover:shadow-lg;
  }

  .button-outline-icon {
    @apply button-base border-2 border-accent active:bg-accent/30;
  }

  button.button-outline-icon:enabled,
  a.button-outline-icon {
    @apply hover:bg-accent/10 hover:shadow-lg;
  }

  .button-text {
    @apply button-base px-4 py-2 focus:ring-offset-0 active:bg-accent/30;
  }

  button.button-text:enabled,
  a.button-text {
    @apply hover:bg-accent/10;
  }

  .button-fill {
    @apply button-base bg-accent px-4 py-2 text-accent-contrast active:bg-accent-hover/80;
  }

  button.button-fill:enabled,
  a.button-fill {
    @apply hover:bg-accent-hover hover:shadow-lg;
  }

  .button-full {
    @apply w-full justify-center;
  }

  .button-large {
    @apply p-4 text-sm sm:text-lg;
  }

  .grouping-cards-container {
    @apply flex flex-col gap-4 sm:gap-6;
  }

  .grouping-card {
    @apply bg-overlay py-4 sm:mx-4 sm:rounded-xl;
  }

  .grouping-card-title {
    @apply geometric-title my-2 text-accent;
  }

  .grouping-card-strip {
    @apply px-responsive py-2 text-sm sm:py-4 sm:text-lg;
  }

  .grouping-card-strip-button {
    @apply w-full touch-manipulation rounded-sm font-bold text-accent transition-all hover:bg-accent/10 focus:outline-none focus:ring focus:ring-accent focus:ring-offset-0 active:bg-accent/30 disabled:pointer-events-none sm:gap-6;
  }

  .grouping-card-strip-button-inner {
    @apply ml-4 flex flex-row items-center gap-4 border-b-2 border-accent/10 py-3 pr-4 text-start text-sm sm:ml-6 sm:py-6 sm:pr-6 sm:text-lg;
  }
}

@layer utilities {
  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }

  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .pl-safe {
    padding-left: env(safe-area-inset-left);
  }

  .pr-safe {
    padding-right: env(safe-area-inset-right);
  }

  .px-safe {
    @apply pl-safe pr-safe;
  }

  .py-safe {
    @apply pt-safe pb-safe;
  }

  .p-safe {
    @apply pt-safe pb-safe pl-safe pr-safe;
  }

  .standard-page-container {
    @apply mx-auto max-w-screen-xl;
  }

  .reduced-page-container {
    @apply mx-auto max-w-screen-md;
  }

  .px-responsive {
    @apply px-4 sm:px-6;
  }

  .text-condensed {
    font-stretch: condensed;
    @apply font-grotesque uppercase;
  }

  .geometric-title {
    @apply font-geometric font-bold uppercase;
  }

  .currency-value {
    @apply font-geometric font-bold tabular-nums;
  }

  .themed-scrollbar {
    --scrollbar-color-thumb: rgb(var(--color-overlay-negative));
    --scrollbar-color-track: rgb(var(--color-overlay-negative) / 10%);
    --scrollbar-width: auto;
    --scrollbar-width-legacy: 0.5rem;
  }

  @supports (scrollbar-width: auto) {
    .themed-scrollbar {
      scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
      scrollbar-width: var(--scrollbar-width);
    }
  }

  @supports selector(::-webkit-scrollbar) {
    .themed-scrollbar::-webkit-scrollbar-thumb {
      background: var(--scrollbar-color-thumb);
      @apply rounded-full;
    }
    .themed-scrollbar::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
    }
    .themed-scrollbar::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
    }
  }

  .transition-appear {
    animation:
      210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
  }

  @media (prefers-reduced-motion: reduce) {
    .transition-appear {
      @apply animate-none;
    }
  }

  .bg-card-corner-over {
    @apply bg-over-distant bg-no-repeat sm:bg-over;
  }

  .bg-card-corner-close {
    @apply bg-close bg-no-repeat;
  }
}
